<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col cols="4">
              <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="Học kỳ"
                label-for="input-default"
              >
                <v-select
                  id="id"
                  v-model="courseSemesterSelected"
                  label="name"
                  :reduce="option => option.id"
                  :options="courseSemesters"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="getDataFromStore"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="SearchIcon" /> Tìm kiếm
                </span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="exportExcel"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="DownloadIcon" /> Xuất excel
                </span>
              </b-button>
            </b-col>
            <!-- Per Page -->
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <vue-good-table
                class="my-table"
                mode="remote"
                :columns="columns"
                :rows="dataSource"
                :pagination-options="paginationOptions"
                :total-rows="total"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Common -->
                  <span>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị 1 đến </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap">của {{ total }} bản ghi</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="total"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </b-container>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormGroup, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import * as XLSX from 'xlsx'

export default {
  name: 'PaymentStatusOfStudent',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BFormGroup,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        courseSemesterId: 1,
        paymentStatus: null,
        status: 1,
        sort: '',
        organizationId: '',
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã dịch vụ của merchant',
          field: 'merchantServiceId',
          thClass: 'text-center',
        },
        {
          label: 'Mã đơn hàng',
          field: 'merchantOrderId',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Số tiền thanh toán',
          field: 'amount',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Hình thức thanh toán',
          field: 'paymentAction',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Mã loại hình dịch vụ',
          field: 'serviceCategory',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Mã kênh thanh toán',
          field: 'channelId',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Mã thiết bị',
          field: 'device',
          thClass: 'text-center',
        },
        {
          label: 'Phương thức thanh toán',
          field: 'paymentMethod',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Mô tả đơn hàng',
          field: 'description',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Thời gian giao dịch',
          field: 'createdAt',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'IP của khách hàng',
          field: 'clientIp',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Trạng thái thanh toán',
          field: 'responseCode',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Mô tả trạng thái',
          field: 'responseDescription',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
      ],
      dataSource: [],
      total: 0,
      courseSemesterOption: [],
      courseSemesterSelected: null,
      filterCourseSemester: {
        currentPage: 1,
        itemsPerPage: 1000,
        courseId: 1,
        status: 1,
      },
    }
  },
  computed: {
    ...mapGetters({
      dataList: 'payment/dataList',
      totalRows: 'payment/totalRows',
      courseSemesters: 'courseSemester/dataLists',
    }),
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.readCourseSemester(this.filterCourseSemester),
        this.readData(this.filter),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
      this.dataSource = this.dataList
      this.total = this.totalRows
      if (this.courseSemesters.length > 0) {
        this.courseSemesterSelected = this.courseSemesters[0].id
      }
    }
  },
  methods: {
    ...mapActions({
      readData: 'payment/readData',
      readAllData: 'payment/readAllData',
      readCourseSemester: 'courseSemester/getData',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataFromStore()
    },
    async getDataFromStore() {
      this.isLoading = true
      try {
        await this.readData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.dataSource = this.dataList
        this.total = this.totalRows
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getDataFromStore()
    },
    async exportExcel() {
      if (this.dataSource.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất báo cáo',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const data = []
      const header = {}
      this.columns.forEach(column => {
        header[column.field] = column.label
      })
      const headerKeys = Object.keys(header)
      const dataSourceKeys = Object.keys(this.dataSource[0])
      const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
      duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
      // thực hiện call API lấy toàn bộ dữ liệu
      const dataRows = await this.readAllData({ ...this.filter, ...{ currentPage: 1, itemsPerPage: 10000000 } })
      dataRows.forEach(dataSource => {
        const elm = {}
        duplicates.forEach(key => {
          elm[header[key]] = dataSource[key]
        })
        data.push(elm)
      })
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'khoanthu')
      XLSX.writeFile(wb, 'baocaokhoanthu.xlsx')
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'orderNo') this.updateParams({ sort: `2_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'studentName')) this.updateParams({ studentName: columnFilters.studentName })
      if (Object.hasOwn(columnFilters, 'studentCode')) this.updateParams({ studentCode: columnFilters.studentCode })
      if (Object.hasOwn(columnFilters, 'majorName')) this.updateParams({ majorName: columnFilters.majorName })
      if (Object.hasOwn(columnFilters, 'className')) this.updateParams({ className: columnFilters.className })
      if (Object.hasOwn(columnFilters, 'revenueName')) this.updateParams({ revenueName: columnFilters.revenueName })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ paymentStatus: columnFilters.status })
      await this.getDataFromStore()
    },
  },
}
</script>
